<template>
<div class="commonFunctions">
  <a-spin :indicator="indicator" :spinning="spinning">

    <div class="table_Info">
      <a-button type="primary" @click="addBtn"> 新增</a-button>
      <a-table :columns="columns" style="min-height: 500px;margin-top: 10px"  :data-source="InfoTable" :pagination="false" rowKey="id">
        <span slot="operate" slot-scope="text,record">
            <a-switch v-model="record.is_open=== 1 ? true:false" checked-children="是" un-checked-children="否" @click="switchOpen(record.id,record.is_open === 1 ? 0 : 1)" />
          </span>

         <span slot="edit" slot-scope="item">
             <a style="margin-right: 10px" @click="editBtn(item.id)"> 修改</a>
             <a-popconfirm
                 title="确定删除?"
                 ok-text="是"
                 cancel-text="否"
                 @confirm="delRules(item.id)"
                 @cancel="cancel"
             >
            <a >删除</a>
            </a-popconfirm>
           </span>
      </a-table>
      <div  style="display: flex;justify-content: flex-end;right: 35px;margin-top: 15px;">
        <a-pagination v-model="pagination.current"
                      :total="pagination.total"
                      @change="tableChange" />
      </div>
    </div>

    <a-modal v-model="addShow" title="新增" @ok="addOk">
      <a-form-model :rules="rules" :model="addForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="addItem" hideRequiredMark>
        <a-form-model-item label="功能名称" prop="name">
          <a-input placeholder="输入名称" v-model="addForm.name" />
        </a-form-model-item>
        <a-form-model-item label="功能类型" >
          <a-select placeholder="选择功能类型"  style="width: 275px"  >
            <a-select-option v-for="item in typeList"   :value="item.code"  @click="typeSelect(item.code)">{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <div style="display: flex;width: 75%;flex-wrap: wrap;padding: 10px 30px;justify-content: space-between;height: 100px">
          <div class="switches">是否开启: &nbsp;<a-switch @change="openWitch" checked-children="是" un-checked-children="否" default-un-checked /></div>
        </div>

      </a-form-model>
    </a-modal>
    <a-modal v-model="editShow" title="修改" @ok="editOk">
      <a-form-model :rules="rules" :model="editForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="editItem" hideRequiredMark>
        <a-form-model-item label="功能名称" prop="name">
          <a-input placeholder="输入名称" v-model="editForm.name" />
        </a-form-model-item>
        <a-form-model-item label="功能类型" prop="func_type_code">
          <a-select placeholder="选择功能类型" v-model="editForm.func_type_code"  style="width: 275px"  >
            <a-select-option v-for="item in typeList"   :value="item.code"  @click="editSelect(item.code)">{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <div style="display: flex;width: 75%;flex-wrap: wrap;padding: 10px 30px;justify-content: space-between;height: 100px">
          <div class="switches">是否开启: &nbsp;<a-switch :checked="editForm.is_open!==0" @click="openEdit(editForm.is_open === 1? 0 : 1)"
                                                      checked-children="是" un-checked-children="否"  /></div>
        </div>

      </a-form-model>
    </a-modal>

  </a-spin>
</div>
</template>

<script>
import {
  delFunction,getFunctionId,
  getFunctionList,
  geTypeList,
  postFunction, putFunction,
  putFunctionOpen,
} from "@/service/medicalConference_api";

export default {
  name: "commonFunctions",
  data(){
    return{
      spinning: false,
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
      labelCol: { span: 5 },
      wrapperCol: { span: 14 },
      InfoTable:[],
      typeList:[],
      addShow:false,
      editShow:false,
      addForm:{
        is_open: 0,
      },
      editForm:{
      },
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },
      rules: {
        name:[{ required: true, message: '请输入名称', trigger: 'blur' }],
        func_type_code:[{ required: true, message: '请选择功能类型', trigger: 'change' }],
      },
      columns:[
        {
          title: '功能名称',
          dataIndex: 'name',
        },
        {
          title: '功能类型',
          dataIndex: 'func_type_code',
        },
        {
          title: '是否开启',
          scopedSlots: { customRender: 'operate' },
        },
        {
          title: '创建时间',
          dataIndex: 'created_time',
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'edit' },
        },
      ],
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"首页常用功能管理")
  },
  mounted() {
    this.getFunctionItem()
    this.getTypeList()
  },
  methods:{
    async getFunctionItem(pageNum){
      const Info = await  getFunctionList(pageNum)
      if (Info.code === 0){
        this.InfoTable = Info.data.cus
        this.pagination.total = Info.data.count
      }
    },
    async getTypeList(){
      const Infos = await geTypeList()
      for (let i =0; i < Infos.data.types.length; i++){
        if ( Infos.data.types[i].is_home_func===1 && Infos.data.types[i].is_open===1){
          this.typeList.push({
            name: Infos.data.types[i].name,
            code : Infos.data.types[i].code
          })
        }
      }
    },
    tableChange(e){
      this.getFunctionItem(e)
    },
    async switchOpen(id, open){
      const data = {
        is_open : open
      }
      const info =await putFunctionOpen(id,data)
      if (info.code === 0){
        await this.getFunctionItem(this.pagination.current)
        this.$message.success('修改成功')
      }
    },
     addBtn(){
      this.addShow = true
    },
    async addOk(){
      this.$refs.addItem.validate(async valid =>{
        if(valid){
          const addFrom = await postFunction(this.addForm)
          if (addFrom.code === 0){
            await this.getFunctionItem(this.pagination.current)
            this.$message.success("添加成功")
            this.addShow = false
            this.$refs.addItem.resetFields();
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    openWitch(checked){
      if (checked === false){
        this.addForm.is_open = 0
      }else {
        this.addForm.is_open = 1
      }
    },
    typeSelect(code){
      this.addForm.func_type_code = code
    },
    editSelect(code){
      this.editForm.func_type_code = code
    },
    async editBtn(id){
      this.editId = id
      const Info = await getFunctionId(id)
      this.editForm = Info.data
      this.editShow = true
    },
    openEdit(open){
      this.editForm.is_open = open
    },
    async editOk(){
      this.$refs.editItem.validate(async valid =>{
        if(valid){
          const edit = await putFunction(this.editId,this.editForm)
          if (edit.code === 0){
            await this.getFunctionItem(this.pagination.current)
            this.$refs.editItem.resetFields();
            this.$message.success("修改成功")
            this.editShow=false
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //删除功能
    async delRules(id){
      const del = await delFunction(id)
      if (del.code === 0 ){
        await this.getFunctionItem(this.pagination.current)
        this.$message.success('删除成功')
      }
      else {
        this.$message.error('删除失败')
      }
    },
    cancel(){
      this.$message.warning('取消删除');
    },
  }
}
</script>

<style lang="scss" scoped>
.commonFunctions{
  margin: -15px -15px;
  .table_Info{
    width: 96%;
    margin: 0 auto;
    padding: 10px;
  }
  .switches{
    width: 30%;
    margin:10px auto;
    text-align: right;
  }
}

</style>
